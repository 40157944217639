import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="edit-item"
export default class extends Controller {
  static targets = [
    "itemLink",
    "authLicensee",
    "federations",
    "federationsCheckboxes",
    "authGender",
    "bibsForm",
    "clubName",
    "clubNameMandatory",
    "checkbox",
    "checkedFederationsList",
    "siffa",
    "additionalFieldFields",
    "participantNumbering",
    "documentTypeCheckbox",
  ];
  static values = { page: String, price: String };

  declare itemLinkTargets: HTMLElement[];
  declare hasItemLinkTarget: boolean;
  declare pageValue: string;
  declare hasPriceValue: boolean;
  declare priceValue: string;
  // genre
  declare authGenderTargets: HTMLInputElement[];
  declare hasAuthGenderTarget: boolean;
  // dossards
  declare bibsFormTarget: HTMLElement;
  declare hasBibsFormTarget: boolean;
  declare participantNumberingTargets: HTMLInputElement[];
  declare hasParticipantNumberingTarget: boolean;
  // licenciés
  declare authLicenseeTargets: HTMLInputElement[];
  declare hasAuthLicenseeTarget: boolean;
  //fédérations
  declare federationsTarget: HTMLElement;
  declare hasFederationsTarget: boolean;
  declare federationsCheckboxesTarget: HTMLElement;
  declare hasFederationsCheckboxesTarget: boolean;
  declare checkboxTargets: HTMLInputElement[];
  declare hasCheckboxTarget: HTMLInputElement[];
  declare checkedFederationsListTarget: HTMLElement;
  declare hasCheckedFederationsListTarget: boolean;
  declare siffaTarget: HTMLElement;
  declare hasSiffaTarget: boolean;
  //items/champs additionnels
  declare clubNameTarget: HTMLInputElement;
  declare hasClubNameTarget: boolean;
  declare clubNameMandatoryTarget: HTMLInputElement;
  declare hasClubNameMandatoryTarget: boolean;
  declare additionalFieldFieldsTargets: HTMLElement[];
  declare hasAdditionalFieldFieldsTarget: boolean;
  // documents
  declare documentTypeCheckboxTargets: HTMLInputElement[];
  declare hasDocumentTypeCheckboxTarget: boolean;

  additionalFieldsForm = null;

  connect() {
    this.selectedTabs();
    this.toggleLicensee();
    this.toggleClubNameMandatory();
    this.displayCheckedFederations();
    this.displaySiffaInput();
    if (this.hasAdditionalFieldFieldsTarget) {
      this.additionalFieldsForm = this.additionalFieldFieldsTargets[0].outerHTML;
      this.hideEmptyAddFieldsForm();
    }
    setTimeout(() => {
      this.scrollToPrice();
    }, 500);
    if (this.hasParticipantNumberingTarget) {
      this.bibs();
    }
  }

  selectedTabs() {
    if (this.hasItemLinkTarget) {
      for (let link of this.itemLinkTargets) {
        let pageLink = link.getAttribute("href");
        if (pageLink.includes(this.pageValue)) {
          link.className = "tab-selected";
        } else {
          link.className = "tab";
        }
      }
    }
  }

  bibs() {
    if (this.hasParticipantNumberingTarget && this.hasBibsFormTarget) {
      let selectedValue = this.participantNumberingTargets.find((input) => input.checked)?.value;
      if (selectedValue == "range") {
        this.bibsFormTarget.classList.remove("hidden");
      } else {
        this.bibsFormTarget.classList.add("hidden");
      }
    }
  }

  toggleLicensee() {
    if (this.hasAuthLicenseeTarget) {
      for (let radioBtn of this.authLicenseeTargets) {
        if (radioBtn.checked) {
          if (radioBtn.value == "no-validation" || radioBtn.value == "unlicensed") {
            this.hideElt(this.federationsTarget);
          } else {
            this.displayElt(this.federationsTarget);
          }
        }
      }
    }
  }
  toggleFederationsCb() {
    if (this.hasFederationsTarget && this.hasFederationsCheckboxesTarget) {
      this.federationsCheckboxesTarget.classList.toggle("hidden");
    }
  }

  toggleClubNameMandatory() {
    if (this.hasClubNameTarget && this.hasClubNameMandatoryTarget) {
      if (this.clubNameTarget.checked) {
        this.displayElt(this.clubNameMandatoryTarget);
      } else {
        this.hideElt(this.clubNameMandatoryTarget);
      }
    }
  }

  displayElt(elt: HTMLElement) {
    if (elt.classList.contains("hidden")) {
      elt.classList.remove("hidden");
    }
  }

  hideElt(elt: HTMLElement) {
    if (!elt.classList.contains("hidden")) {
      elt.classList.add("hidden");
    }
  }

  displayCheckedFederations() {
    if (this.hasCheckedFederationsListTarget) {
      let list = this.checkedFederationsListTarget.innerText.split(", ");
      this.displaySiffaInput();
      for (let cb of this.checkboxTargets) {
        let federationName = cb.labels[0].innerText;
        if (cb.checked && !list.includes(federationName)) {
          list.push(federationName);
        } else if (!cb.checked && list.includes(federationName))
          list = list.filter((name) => {
            return name != federationName;
          });
      }
      this.checkedFederationsListTarget.innerText = list
        .filter((name) => {
          return name != "";
        })
        .join(", ");
    }
  }

  filterFederations(event) {
    if (this.hasFederationsCheckboxesTarget) {
      let inputsCb = this.federationsCheckboxesTarget.getElementsByTagName("input");
      const search = event.target.value;
      for (let cb of inputsCb) {
        if (cb.type == "checkbox" && cb.labels && search.trim() != "") {
          const label = cb.labels[0].innerText;
          if (label.toLowerCase().includes(search.toLowerCase())) {
            cb.parentElement?.classList.remove("hidden");
          } else {
            cb.parentElement?.classList.add("hidden");
          }
        } else {
          cb.parentElement?.classList.remove("hidden");
        }
      }
    }
  }

  displaySiffaInput() {
    if (this.hasSiffaTarget) {
      let ffa = document.getElementById("item_federation_ids_1");
      if (ffa.checked) {
        this.displayElt(this.siffaTarget);
      } else {
        this.hideElt(this.siffaTarget);
      }
    }
  }

  addAdditionalField() {
    let index = 0;
    if (this.hasAdditionalFieldFieldsTarget) {
      let indexes = [];
      for (let div of this.additionalFieldFieldsTargets) {
        indexes.push(parseInt(div.dataset.index));
      }
      index = this.additionalFieldFieldsTargets.length;
      while (indexes.includes(index)) {
        index++;
      }
    }
    if (this.hasAdditionalFieldFieldsTarget || this.additionalFieldsForm != null) {
      let text = this.additionalFieldsForm.replaceAll(/fields_attributes_\d{1,3}/g, `fields_attributes_${index}`);
      text = text.replaceAll(
        /item\[additional_fields_attributes\]\[\d{1,3}\]/g,
        `item[additional_fields_attributes][${index}]`,
      );
      text = text.replaceAll(/data-index="\d{1,3}\"/g, `data-index="${index}"`);
      document.getElementById("additional_fields").insertAdjacentHTML("beforeend", text);
      document.getElementById(`item_additional_fields_attributes_${index}_label`).value = null;
    }
  }

  removeAdditionalField(event) {
    if (event.target.parentElement.parentElement.parentElement.hasAttribute("data-edit-item-target")) {
      event.target.parentElement.parentElement.parentElement.remove();
    }
  }

  hideEmptyAddFieldsForm() {
    if (this.hasAdditionalFieldFieldsTarget && this.additionalFieldFieldsTargets.length == 1) {
      let index = parseInt(this.additionalFieldFieldsTargets[0].dataset.index);
      let inputLabel = document.getElementById(`item_additional_fields_attributes_${index}_label`);
      if (inputLabel.value.trim() == "") {
        this.hideElt(this.additionalFieldFieldsTargets[0]);
      }
    }
  }

  removeHiddenForm() {
    if (this.hasAdditionalFieldFieldsTarget && this.additionalFieldFieldsTargets[0].classList.contains("hidden")) {
      this.additionalFieldFieldsTargets[0].remove();
    }
  }

  scrollToPrice() {
    if (this.hasPriceValue && this.priceValue != "0") {
      const priceTarget = document.getElementById(`price-${this.priceValue}`);
      if (priceTarget) {
        priceTarget.scrollIntoView({ behavior: "smooth" });
      }
    }
  }

  checkCertificates(event) {
    if (this.hasDocumentTypeCheckboxTarget) {
      if (event.target.value == "2" && event.target.checked) {
        let other_certif = this.documentTypeCheckboxTargets.find((cb) => cb.value == "14");
        if (other_certif) {
          other_certif.checked = false;
        }
      } else if (event.target.value == "14" && event.target.checked) {
        let other_certif = this.documentTypeCheckboxTargets.find((cb) => cb.value == "2");
        if (other_certif) {
          other_certif.checked = false;
        }
      }
    }
  }

  calculateAge(event) {
    let hint = event.target.parentElement.nextSibling;
    if (event.target.value != "" && !event.target.value.startsWith("0")) {
      const inputDate = Date.parse(event.target.value);
      const baseDate = Date.parse(event.target.dataset.date.replaceAll('"', ""));
      let diff = (baseDate - inputDate) / (1000 * 60 * 60 * 24 * 365.25);
      hint.innerText += ` (${Math.floor(diff)} ans)`;
    } else {
      hint.innerText = hint.innerText.split(" (")[0];
    }
  }
}
